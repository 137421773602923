<template>
    <a-container fluid class='pa-0 ma-0'>
        <a-container class='px-5 py-0'>
            <a-row align="center" justify="center">
                <a-col cols='auto' class='mx-3 mt-3'>
                    <slot name='right-header' />
                </a-col>
            </a-row>
        </a-container>
        <a-divider v-if='_success'/>
        <a-container class='px-0 pt-0 pb-2'>
            <a-row
                dense
                justify='center'
                align='center'
                class="my-4 px-0"
            >
                <a-col class='body-b2-bold pa-2 rounded-lg mx-4 text-center' :style='statusStyle'>
                    {{currentStatus[_statusTitle]}}
                </a-col>
            </a-row>
            <slot name='status-body' />
        </a-container>
    </a-container>
</template>

<script>
    export default {
        name: 'AStatusCard',
        props: {
            /**
             * Status list for the desired entity
             * @type {Array}
             * @default {[]}
             */
            _statusList: {
                type: Array,
                default: [],
                required: true,
            },
            /**
             * Routing status
             * @type {Boolean}
             * @default {false}
             */
             _success: {
                type: Boolean,
                default: false,
                required: true,
            },
            /**
             * Identifier for the current status in which the entity is in
             * @type {Number}
             * @default {undefined}
             */
            _currentStatusId: {
                type: Number,
                default: undefined,
                required: true,
            },
            /**
             * The object key for the status identifier
             * @type {String}
             * @default {''}
             */
            _statusKey: {
                type: String,
                default: '',
                required: true,
            },
            /**
             * The object key for the status title
             * @type {String}
             * @default {''}
             */
            _statusTitle: {
                type: String,
                default: '',
                required: true,
            },
            /**
             * The object key for the status border color
             * @type {String}
             * @default {''}
             */
            _statusBorder: {
                type: String,
                default: '',
                required: true,
            },
            /**
             * The object key for the status background color
             * @type {String}
             * @default {''}
             */
             _statusBackground: {
                type: String,
                default: '',
                required: true,
            },
        },
        data: function () {
            return {
                file: null,
            };
        },
        computed: {
            statusBorder: function () {
                return `border-left: .5rem solid ${this.currentStatus ? this.currentStatus[this._statusColor] : ''};`;
            },
            statusStyle: function () {
                return `color: black; border: ${this.currentStatus ? this.currentStatus[this._statusBorder] : ''} solid 1px; background: ${this.currentStatus ? this.currentStatus[this._statusBackground] : 'var(--v-bar-base)'};`;
            },
            currentStatus: function () {
                return this._statusList.find(status => (status[this._statusKey] === this._currentStatusId)) || {};
            },
        },
        mounted: function () {
            this.load();
        },
        methods: {
            load: function () {

            },
        },

    };
</script>

<style scoped lang='scss'>
.body-b2-bold {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    text-decoration-skip-ink: none;
}

</style>
