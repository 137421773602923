const paths = [
    {
        path: '/maintenace-list',
        title: 'ATTENDANCES',
        name: 'MaintenanceList',
        icon: 'fa fa-hard-hat',
        menu: 'maintenance',
        component: () => import(/* webpackChunkName: "maintenance" */ /* webpackMode: "lazy" */ '@/pages/maintenance/MaintenanceList.vue'),
    },
    {
        path: '/maintenace-steps',
        title: 'ATTENDANCES',
        name: 'MaintenanceSteps',
        component: () => import(/* webpackChunkName: "maintenance" */ /* webpackMode: "lazy" */ '@/pages/maintenance/MaintenanceSteps.vue'),
    },
    {
        path: '/maintenance-wizzard',
        title: 'MAINTENANCE_WIZZARD',
        name: 'MaintenanceWizzard',
        component: () => import(/* webpackChunkName: "maintenance" */ /* webpackMode: "lazy" */ '@/pages/maintenance/MaintenanceWizzard.vue'),
    },
    {
        path: '/maintenance-form',
        title: 'FORM',
        name: 'MaintenanceForm',
        component: () => import(/* webpackChunkName: "maintenance" */ /* webpackMode: "lazy" */ '@/pages/maintenance/MaintenanceForm.vue'),
    },
    {
        path: '/maintenance-form-new',
        title: 'FORM',
        name: 'MaintenanceFormNew',
        component: () => import(/* webpackChunkName: "maintenance" */ /* webpackMode: "lazy" */ '@/pages/maintenance/MaintenanceFormNew.vue'),
    },
    {
        path: '/maintenance-form-detail',
        title: 'FORM',
        name: 'MaintenanceFormDetail',
        component: () => import(/* webpackChunkName: "maintenance" */ /* webpackMode: "lazy" */ '@/pages/maintenance/MaintenanceFormDetail.vue'),
    },
    {
        path: '/maintenance-detail-intervention-detail',
        title: 'INTERVENTION_DETAILS',
        name: 'MaintenanceDetailInterventionDetail',
        component: () => import(/* webpackChunkName: "maintenance" */ /* webpackMode: "lazy" */ '@/pages/maintenance/MaintenanceDetailInterventionDetail.vue'),
    },
    {
        path: '/maintenance-detail-intervention-detail',
        title: 'SERVICE_DETAILS',
        name: 'MaintenanceDetailServiceDetail',
        component: () => import(/* webpackChunkName: "maintenance" */ /* webpackMode: "lazy" */ '@/pages/maintenance/MaintenanceDetailServiceDetail.vue'),
    },
    {
        path: '/action-after-maintenance-list',
        title: 'ACTIONS_AFTER_MAINTENANCES',
        name: 'ActionAfterMaintenanceList',
        menu: 'maintenance',
        categories: [
          'configurations',
        ],
        icon: 'widgets',
        component: () => import(/* webpackChunkName: "maintenance" */ /* webpackMode: "lazy" */ '@/pages/maintenance/actionAfterMaintenance/ActionAfterMaintenanceList.vue'),
    },
    {
        path: '/action-after-maintenance',
        title: 'ACTION_AFTER_MAINTENANCE',
        name: 'ActionAfterMaintenance',
        component: () => import(/* webpackChunkName: "maintenance" */ /* webpackMode: "lazy" */ '@/pages/maintenance/actionAfterMaintenance/ActionAfterMaintenance.vue'),
    },
    {
        path: '/action-after-maintenance-edit-history',
        name: 'ActionAfterMaintenanceEditHistory',
        component: () => import(/* webpackChunkName: "maintenance" */ /* webpackMode: "lazy" */ '@/pages/maintenance/actionAfterMaintenance/ActionAfterMaintenanceEditHistory.vue'),
    },
    {
        path: '/ad-hoc-maintenance-list',
        title: 'ISSUE_AUTOMATICALLY_IN_AD_HOC_MAINTENANCE',
        name: 'AdHocMaintenanceList',
        menu: 'maintenance',
        categories: [
          'configurations',
        ],
        icon: 'widgets',
        component: () => import(/* webpackChunkName: "maintenance" */ /* webpackMode: "lazy" */ '@/pages/maintenance/adHocMaintenance/AdHocMaintenanceList.vue'),
    },
    {
        path: '/ad-hoc-maintenance',
        title: 'AD_HOC_MAINTENANCE',
        name: 'AdHocMaintenance',
        component: () => import(/* webpackChunkName: "maintenance" */ /* webpackMode: "lazy" */ '@/pages/maintenance/adHocMaintenance/AdHocMaintenance.vue'),
    },
    {
        path: '/ad-hoc-maintenance-edit-history',
        title: 'AD_HOC_MAINTENANCE_EDIT_HISTORY',
        name: 'AdHocMaintenanceEditHistory',
        component: () => import(/* webpackChunkName: "maintenance" */ /* webpackMode: "lazy" */ '@/pages/maintenance/adHocMaintenance/AdHocMaintenanceEditHistory.vue'),
    },
    {
        path: '/maintenance-by-deadline-list',
        title: 'MAINTENANCES_BY_DEADLINE',
        name: 'MaintenanceByDeadlineList',
        icon: 'fa fa-hard-hat',
        menu: 'maintenance',
        component: () => import(/* webpackChunkName: "maintenance" */ /* webpackMode: "lazy" */ '@/pages/maintenance/MaintenanceByDeadlineList.vue'),
    },
    {
        path: '/maintenance-satisfaction-survey-list',
        title: 'SATISFACTION_SURVEYS',
        name: 'MaintenanceSatisfactionSurveyList',
        icon: 'fa fa-hard-hat',
        menu: 'maintenance',
        component: () => import(/* webpackChunkName: "maintenance" */ /* webpackMode: "lazy" */ '@/pages/maintenance/MaintenanceSatisfactionSurveyList.vue'),
    },
    {
        path: '/maintenance-satisfaction-survey-wizard',
        title: 'SATISFACTION_SURVEY_WIZARD',
        name: 'MaintenanceSatisfactionSurveyWizard',
        component: () => import(/* webpackChunkName: "maintenance" */ /* webpackMode: "lazy" */ '@/pages/maintenance/MaintenanceSatisfactionSurveyWizard.vue'),
    },
    {
        path: '/maintenance-satisfaction-survey-detail',
        name: 'MaintenanceSatisfactionSurveyDetails',
        title: 'SURVEY_SAMPLES',
        favCategory: 'registration',
        component: () => import(/* webpackChunkName: "maintenance" */ /* webpackMode: "lazy" */ '@/pages/maintenance/MaintenanceSatisfactionSurveyDetails.vue'),
    },
    {
        path: '/maintenance-satisfaction-survey-issue-type-selection',
        name: 'MaintenanceSatisfactionSurveyIssueTypeSelection',
        title: 'SELECT_ISSUE_TYPE',
        component: () => import(/* webpackChunkName: "maintenance" */ /* webpackMode: "lazy" */ '@/pages/maintenance/MaintenanceSatisfactionSurveyIssueTypeSelection.vue'),
    },
    {
        path: '/maintenance-satisfaction-survey-issue-origin-type-selection',
        name: 'MaintenanceSatisfactionSurveyIssueOriginTypeSelection',
        title: 'SELECT_ISSUE_ORIGIN_TYPE',
        component: () => import(/* webpackChunkName: "maintenance" */ /* webpackMode: "lazy" */ '@/pages/maintenance/MaintenanceSatisfactionSurveyIssueOriginTypeSelection.vue'),
    },
    {
        path: '/maintenance-satisfaction-survey-ticket-category-selection',
        name: 'MaintenanceSatisfactionSurveyTicketCategorySelection',
        title: 'SELECT_TICKET_CATEGORY',
        component: () => import(/* webpackChunkName: "maintenance" */ /* webpackMode: "lazy" */ '@/pages/maintenance/MaintenanceSatisfactionSurveyTicketCategorySelection.vue'),
    },
    {
        path: '/maintenance-satisfaction-survey-form',
        name: 'MaintenanceSatisfactionSurveyForm',
        title: 'SATISFACTION_SURVEY',
        component: () => import(/* webpackChunkName: "maintenance" */ /* webpackMode: "lazy" */ '@/pages/maintenance/MaintenanceSatisfactionSurveyForm.vue'),
    },
    {
        path: '/maintenace-monitoring-list',
        name: 'MaintenanceMonitoringList',
        title: 'MAINTENANCE_MONITORING',
        icon: 'fa fa-hard-hat',
        menu: 'maintenance',
        favCategory: 'maintenance',
        component: () => import(/* webpackChunkName: "maintenance" */ /* webpackMode: "lazy" */ '@/pages/maintenance/MaintenanceMonitoringList.vue'),
    },
    {
        path: '/maintenace-done-pending-list',
        name: 'MaintenanceDonePendingList',
        title: 'MAINTENANCE_DONE_PENDING_LIST',
        icon: 'fa fa-hard-hat',
        menu: 'maintenance',
        favCategory: 'maintenance',
        component: () => import(/* webpackChunkName: "maintenance" */ /* webpackMode: "lazy" */ '@/pages/maintenance/MaintenanceDonePendingList.vue'),
    },
    {
        path: '/maintenance',
        title: 'MAINTENANCE_DETAIL',
        name: 'Maintenance',
        favCategory: 'maintenance',
        component: () => import(/* webpackChunkName: "maintenance" */ /* webpackMode: "lazy" */ '@/pages/maintenance/Maintenance.vue'),
    },
    {
        path: '/maintenance-problems-found-chart',
        title: 'PROBLEMS_FOUND',
        name: 'MaintenanceProblemsFoundChart',
        icon: 'fas fa-chart-pie',
        menu: 'maintenance',
        component: () => import(/* webpackChunkName: "maintenance" */ /* webpackMode: "lazy" */ '@/pages/maintenance/MaintenanceProblemsFoundChart.vue'),
    },
    {
        path: '/maintenance-monthly-report',
        title: 'MAINTENANCE_MONTHLY_REPORT',
        name: 'MaintenanceMonthlyReport',
        icon: 'fa fa-file-excel',
        menu: 'maintenance',
        categories: [
            'reports',
        ],
        component: () => import(/* webpackChunkName: "maintenance" */ /* webpackMode: "lazy" */ '@/pages/maintenance/MaintenanceMonthlyReport.vue'),
    },
    {
        path: '/maintenance-recurrent-report',
        title: 'MAINTENANCE_RECURRENT_REPORT',
        name: 'MaintenanceRecurrentReport',
        icon: 'fas fa-file-alt',
        menu: 'maintenance',
        categories: [
          'reports',
        ],
        component: () => import(/* webpackChunkName: "maintenance" */ /* webpackMode: "lazy" */ '@/pages/maintenance/MaintenanceRecurrentReport.vue'),
    },
    {
        path: '/maintenance-photographic-report',
        name: 'MaintenancePhotographicReport',
        title: 'PHOTOGRAPHIC_ACTIVITIES_REPORT',
        icon: 'fas fa-chart-pie',
        menu: 'maintenance',
        favCategory: 'maintenance',
        component: () => import(/* webpackChunkName: "maintenance" */ /* webpackMode: "lazy" */ '@/pages/maintenance/MaintenancePhotographicReport.vue'),
    },
    {
        path: '/maintenance-photographic-pdf-wizard',
        name: 'MaintenancePhotographicPdfWizard',
        title: 'PHOTOGRAPHIC_ACTIVITIES_REPORT',
        icon: 'fas fa-chart-pie',
        favCategory: 'maintenance',
        component: () => import(/* webpackChunkName: "maintenance" */ /* webpackMode: "lazy" */ '@/pages/maintenance/MaintenancePhotographicPdfWizard.vue'),
    },
    {
        path: '/MaintenancesRegardingDeadline',
        title: 'MAINTENANCES_REGARDING_DEADLINE',
        name: 'MaintenanceRegardingDeadline',
        icon: 'fas fa-file-alt',
        menu: 'maintenance',
        categories: [
          'reports',
        ],
        component: () => import(/* webpackChunkName: "maintenance" */ /* webpackMode: "lazy" */ '@/pages/maintenance/MaintenancesRegardingDeadline.vue'),
    },
    {
        path: '/MaintenanceAverageConclusionTime',
        title: 'MAINTENANCE_AVERAGE_CONCLUSION_TIME',
        name: 'MaintenanceAverageConclusionTime',
        icon: 'fas fa-file-alt',
        menu: 'maintenance',
        categories: [
          'reports',
        ],
        component: () => import(/* webpackChunkName: "maintenance" */ /* webpackMode: "lazy" */ '@/pages/maintenance/MaintenanceAverageConclusionTime.vue'),
    },
    {
        path: '/maintenance-summary',
        name: 'MaintenanceSummary',
        title: 'REPORT_MAINTENANCE_SUMMARY',
        icon: 'mdi-file-document',
        menu: 'maintenance',
        categories: [
          'reports',
        ],
        component: () => import(/* webpackChunkName: "maintenance" */ /* webpackMode: "lazy" */ '@/pages/maintenance/MaintenanceSummary.vue'),
    },
    {
        path: '/maintenance-preview',
        name: 'MaintenancePreview',
        component: () => import(/* webpackChunkName: "maintenance" */ /* webpackMode: "lazy" */ '@/pages/maintenance/MaintenancePreview.vue'),
    },
    {
        path: '/maintenance-edit-history',
        name: 'MaintenanceEditHistory',
        component: () => import(/* webpackChunkName: "maintenance" */ /* webpackMode: "lazy" */ '@/pages/maintenance/MaintenanceEditHistory.vue'),
    },
    {
        path: '/maintenance-step-history',
        name: 'HistoryMaintenanceStatus',
        component: () => import(/* webpackChunkName: "maintenance" */ /* webpackMode: "lazy" */ '@/pages/maintenance/HistoryMaintenanceStatus.vue'),
    },
    {
        path: '/maintenance-step-history-detail',
        name: 'HistoryMaintenanceStatusDetail',
        component: () => import(/* webpackChunkName: "maintenance" */ /* webpackMode: "lazy" */ '@/pages/maintenance/HistoryMaintenanceStatusDetail.vue'),
    },
    {
        path: '/maintenance-interventions-edit',
        name: 'MaintenanceInterventiosEdit',
        component: () => import(/* webpackChunkName: "maintenance" */ /* webpackMode: "lazy" */ '@/pages/maintenance/MaintenanceInterventionsEdit.vue'),
    },
    {
        path: '/MaintenanceTotalManHoursByServiceOrder',
        title: 'MAINTENANCE_TOTAL_MAN_HOURS_BY_SERVICE_ORDER',
        name: 'MaintenanceTotalManHoursByServiceOrder',
        icon: 'fas fa-file-alt',
        menu: 'maintenance',
        categories: [
          'reports',
        ],
        component: () => import(/* webpackChunkName: "maintenance" */ /* webpackMode: "lazy" */ '@/pages/maintenance/MaintenanceTotalManHoursByServiceOrder.vue'),
    },
    {
        path: '/MaintenanceDetailedUnproductivitiesReport.vue',
        title: 'DETAILED_UNPRODUCTIVITIES_REPORT',
        name: 'MaintenanceDetailedUnproductivitiesReport',
        icon: 'fas fa-file-alt',
        menu: 'maintenance',
        categories: [
          'reports',
        ],
        component: () => import(/* webpackChunkName: "maintenance" */ /* webpackMode: "lazy" */ '@/pages/maintenance/MaintenanceDetailedUnproductivitiesReport.vue'),
    },
    {
        path: '/maintenances-images-export-wizard',
        name: 'MaintenancesImagesExportWizard',
        title: 'EXPORT_MAINTENANCES_IMAGES',
        icon: 'mdi-image',
        favCategory: 'maintenance',
        component: () => import(/* webpackChunkName: "maintenance" */ /* webpackMode: "lazy" */ '@/pages/maintenance/MaintenancesImagesExportWizard.vue'),
    },
    {
        path: '/maintenance-reversal',
        name: 'MaintenanceReversal',
        component: () => import(/* webpackChunkName: "maintenance" */ /* webpackMode: "lazy" */ '@/pages/maintenance/MaintenanceReversal.vue'),
    },
    {
        path: '/maintenance-operations-and-services-report',
        name: 'MaintenanceOperationsAndServicesReport',
        title: 'REPORT_MAINTENANCE_OPERATIONS_AND_SERVICES',
        icon: 'fas fa-file-alt',
        menu: 'maintenance',
        categories: [
            'reports',
        ],
        component: () => import(/* webpackChunkName: "maintenance" */ /* webpackMode: "lazy" */ '@/pages/maintenance/MaintenanceOperationsAndServicesReport.vue'),
    },
    {
        path: '/satisfaction-survey-origin-type-list',
        name: 'SatisfactionSurveyOriginTypeList',
        title: 'SATISFACTION_SURVEY_ORIGIN_TYPES',
        icon: 'widgets',
        menu: 'maintenance',
        categories: [
          'configurations',
        ],
        component: () => import(/* webpackChunkName: "maintenance" */ /* webpackMode: "lazy" */ '@/pages/maintenance/SatisfactionSurveyOriginTypeList.vue'),
    },
    {
        path: '/satisfaction-survey-origin-type-general',
        name: 'SatisfactionSurveyOriginTypeGeneral',
        component: () => import(/* webpackChunkName: "maintenance" */ /* webpackMode: "lazy" */ '@/pages/maintenance/SatisfactionSurveyOriginTypeGeneral.vue'),
    },
];

export default paths;
