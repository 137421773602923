import i18n from '@/i18n';

const paths = [
    {
        path: '/routing-panel',
        name: 'RoutingPanel',
        title: 'ROUTING_PANEL',
        favCategory: 'maintenance',
        component: () => import(/* webpackChunkName: "routingPanel" */ /* webpackMode: "lazy" */ '@/pages/routingPanel/RoutingPanel.vue'),
    }, {
        path: '/routing-panel-team-working-hours',
        name: 'RoutingPanelTeamWorkingHours',
        title: 'WORKING_HOURS',
        component: () => import(/* webpackChunkName: "routingPanel" */ /* webpackMode: "lazy" */ '@/pages/routingPanel/RoutingPanelTeamWorkingHours.vue'),
    }, {
        path: '/routing-panel-team-limitations',
        name: 'RoutingPanelTeamLimitations',
        title: 'TEAM_LIMITATIONS',
        component: () => import(/* webpackChunkName: "routingPanel" */ /* webpackMode: "lazy" */ '@/pages/routingPanel/RoutingPanelTeamLimitations.vue'),
    }, {
        path: '/routing-panel-profile-form',
        name: 'RoutingPanelProfileForm',
        title: 'ROUTING_PANEL_PROFILE',
        component: () => import(/* webpackChunkName: "routingPanel" */ /* webpackMode: "lazy" */ '@/pages/routingPanel/RoutingPanelProfileForm.vue'),
    }, {
        path: '/routing-panel-teams-selection',
        name: 'RoutingPanelTeamsSelection',
        title: 'ROUTING_PANEL_TEAMS_SELECTION',
        component: () => import(/* webpackChunkName: "routingPanel" */ /* webpackMode: "lazy" */ '@/pages/routingPanel/RoutingPanelTeamsSelection.vue'),
    }, {
        path: '/routing-panel-create',
        name: 'RoutingPanelCreate',
        title: 'NEW_ROUTING',
        component: () => import(/* webpackChunkName: "routingPanel" */ /* webpackMode: "lazy" */ '@/pages/routingPanel/RoutingPanelCreate.vue'),
    }, {
         path: '/routings',
         name: 'Routings',
         title: 'ROUTINGS',
         icon: 'fas fa-route',
         menu: 'maintenance',
         component: () => import(/* webpackChunkName: "routingPanel" */ /* webpackMode: "lazy" */ '@/pages/routingPanel/Routings.vue'),
    }, {
        path: '/routing-panel-issues-maintenance-time-average',
        name: 'RoutingPanelIssuesMaintenanceTimeAverage',
        title: 'ROUTING_PANEL_ISSUES_MAINTENANCE_TIME_AVERAGE',
        icon: 'fas fa-route',
        component: () => import(/* webpackChunkName: "routingPanel" */ /* webpackMode: "lazy" */ '@/pages/routingPanel/RoutingPanelIssuesMaintenanceTimeAverage.vue'),
    }, {
        path: '/routing-scheduler',
        name: 'RoutingScheduler',
        title: 'ROUTING_SCHEDULER',
        icon: 'clock',
        component: () => import(/* webpackChunkName: "routingPanel" */ /* webpackMode: "lazy" */ '@/pages/routingPanel/RoutingScheduler.vue'),
    }, {
        path: '/routing-panel-service-priority-list',
        name: 'RoutingPanelServicePriorityList',
        title: 'SERVICE_PRIORITY',
        component: () => import(/* webpackChunkName: "routingPanel" */ /* webpackMode: "lazy" */ '@/pages/routingPanel/RoutingPanelServicePriorityList.vue'),
    }, {
        path: '/routing-panel-service-priority',
        name: 'RoutingPanelServicePriority',
        title: 'SERVICE_PRIORITY',
        component: () => import(/* webpackChunkName: "routingPanel" */ /* webpackMode: "lazy" */ '@/pages/routingPanel/RoutingPanelServicePriority.vue'),
    }, {
        path: '/routing-panel-purchase-mvrp',
        name: 'PurchaseMvrp',
        component: () => import(/* webpackChunkName: "routingPanel" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ '@/pages/routingPanel/PurchaseMvrp.vue'),

    }, {
        path: '/routing-panel-team-limitations-areas',
        name: 'RoutingPanelTeamLimitationsAreas',
        title: 'TEAM_LIMITATIONS_AREAS',
        component: () => import(/* webpackChunkName: "routingPanel" */ /* webpackMode: "lazy" */ '@/pages/routingPanel/RoutingPanelTeamLimitationsAreas.vue'),
    }, {
        path: '/routing-panel-ignored-dates',
        name: 'RoutingPanelIgnoredDates',
        title: 'ADD_IGNORED_DATE',
        component: () => import(/* webpackChunkName: "routingPanel" */ /* webpackMode: "lazy" */ '@/pages/routingPanel/RoutingPanelIgnoredDates.vue'),
    }, {
        path: '/routing-team-limitations-info',
        name: 'RoutingTeamsLimitationsInfo',
        component: () => import(/* webpackChunkName: "routingPanel" */ /* webpackMode: "lazy" */ '@/pages/routingPanel/RoutingTeamsLimitationsInfo.vue'),
    }, {
        path: '/routing-panel-team-copy-limitations',
        name: 'RoutingPanelTeamCopyLimitations',
        component: () => import(/* webpackChunkName: "routingPanel" */ /* webpackMode: "lazy" */ '@/pages/routingPanel/RoutingPanelTeamCopyLimitations.vue'),
    }, {
        path: '/routing-panel-invalid-issues',
        name: 'RoutingPanelInvalidIssues',
        title: 'ISSUES_TO_FIX_FOR_ROUTING',
        component: () => import(/* webpackChunkName: "routingPanel" */ /* webpackMode: "lazy" */ '@/pages/routingPanel/RoutingPanelInvalidIssues.vue'),
    }, {
        path: '/work-planning',
        name: 'WorkPlanning',
        title: 'WORKING_HOURS',
        component: () => import(/* webpackChunkName: "routingPanel" */ /* webpackMode: "lazy" */ '@/pages/routingPanel/WorkPlanning.vue'),
    }, {
        path: '/working-hours-panel',
        name: 'WorkingHoursPanel',
        title: 'WORKING_HOURS_PANEL',
        component: () => import(/* webpackChunkName: "routingPanel" */ /* webpackMode: "lazy" */ '@/pages/routingPanel/WorkingHoursPanel.vue'),
    }, {
        path: '/working-hours-creation-panel',
        name: 'WorkingHoursCreationPanel',
        title: i18n.t('NEW_ENTITY_PLURAL', { entity: i18n.t('WORKING_HOURS').toLowerCase() }),
        component: () => import(/* webpackChunkName: "routingPanel" */ /* webpackMode: "lazy" */ '@/pages/routingPanel/WorkingHoursCreationPanel.vue'),
    },
];

export default paths;
