const paths = [
    {
        path: '/issues-summary-dialog',
        title: 'ISSUES_SUMMARY_DIALOG',
        name: 'IssuesSummaryDialog',
        component: () => import(/* webpackChunkName: "technicalEvaluation" */ /* webpackMode: "lazy" */ /* webpackPrefetch: true */ '@/pages/technicalEvaluation/IssuesSummaryDialog.vue'),
    },
    {
        path: '/technical-evaluation',
        title: 'TECHNICAL_EVALUATION',
        name: 'TechnicalEvaluation',
        favCategory: 'analysis',
        component: () => import(/* webpackChunkName: "technicalEvaluation" */ /* webpackMode: "lazy" */ '@/pages/technicalEvaluation/TechnicalEvaluation.vue'),
    },
    {
        path: '/technical-evaluation-list',
        title: 'TECHNICAL_EVALUATIONS',
        name: 'TechnicalEvaluationList',
        icon: 'fa fa-file-alt',
        menu: 'analysis',
        component: () => import(/* webpackChunkName: "technicalEvaluation" */ /* webpackMode: "lazy" */ '@/pages/technicalEvaluation/TechnicalEvaluationList.vue'),
    },
    {
        path: '/new-technical-evaluation',
        title: 'NEW_TECHNICAL_ASSESSMENT',
        name: 'NewTechnicalEvaluation',
        component: () => import(/* webpackChunkName: "technicalEvaluation" */ /* webpackMode: "lazy" */ '@/pages/technicalEvaluation/NewTechnicalEvaluation.vue'),
    },
    {
        path: '/schedule-routine-wizard',
        title: 'SCHEDULE_ROUTINE_WIZARD',
        name: 'ScheduleRoutineWizard',
        component: () => import(/* webpackChunkName: "technicalEvaluation" */ /* webpackMode: "lazy" */ '@/pages/technicalEvaluation/ScheduleRoutineWizard.vue'),
    },
    {
        path: '/schedule-routine-general',
        title: 'SCHEDULE_ROUTINE',
        name: 'ScheduleRoutineGeneral',
        component: () => import(/* webpackChunkName: "technicalEvaluation" */ /* webpackMode: "lazy" */ '@/pages/technicalEvaluation/ScheduleRoutineGeneral.vue'),
    },
    {
        path: '/add-samples-by-area',
        title: 'ADD_SAMPLES',
        name: 'AddSamplesByArea',
        component: () => import(/* webpackChunkName: "technicalEvaluation" */ /* webpackMode: "lazy" */ '@/pages/technicalEvaluation/AddSamplesByArea.vue'),
    },
    {
        path: '/technical-evaluation-attributes-report',
        title: 'TECHNICAL_ASSESSMENT_ATTRIBUTES_REPORT',
        name: 'TechnicalEvaluationAttributesReport',
        component: () => import(/* webpackChunkName: "technicalEvaluation" */ /* webpackMode: "lazy" */ '@/pages/technicalEvaluation/TechnicalEvaluationAttributesReport.vue'),
    },
    {
        path: '/technical-evaluation-consolidated-report-wizard',
        title: 'TECHNICAL_ASSESSMENT_CONSOLIDATED_REPORT_WIZARD',
        name: 'TechnicalEvaluationConsolidatedReportWizard',
        component: () => import(/* webpackChunkName: "technicalEvaluation" */ /* webpackMode: "lazy" */ '@/pages/technicalEvaluation/TechnicalEvaluationConsolidatedReportWizard.vue'),
    },
    {
        path: '/technical-evaluation-excel-report-wizard',
        title: 'TECHNICAL_ASSESSMENT_EXCEL_REPORT_WIZARD',
        name: 'TechnicalEvaluationReportWizard',
        component: () => import(/* webpackChunkName: "technicalEvaluation" */ /* webpackMode: "lazy" */ '@/pages/technicalEvaluation/TechnicalEvaluationReportWizard.vue'),
    },
    {
        path: '/technical-evaluation-work-diaries',
        title: 'TECHNICAL_EVALUATION_WORK_DIARIES',
        name: 'TechnicalEvaluationWorkDiaries',
        component: () => import(/* webpackChunkName: "technicalEvaluation" */ /* webpackMode: "lazy" */ '@/pages/technicalEvaluation/TechnicalEvaluationWorkDiaries.vue'),
    },
];

export default paths;
