<template>
    <a-row align='center' justify='end' dense>
        <a-col cols='12'>
            <div class='d-flex align-center'>
                <a-btn
                    fab
                    icon
                    @click='$emit("click:toggle-table-reports")'
                >
                    <a-icon>mdi-arrow-left</a-icon>
                </a-btn>
                <div class='title'>
                    {{$t('REPORTS')}}
                </div>
                <a-spacer></a-spacer>
                <a-btn add @click='dialog = true'>
                    {{$t('NEW_REPORT')}}
                </a-btn>
            </div>
        </a-col>

        <a-col cols='12'>
            <draggable tag='a-row' :list='pivots' hadle='.drag-handler' @sort='updatePivotOrder'>
                <template v-if='loading'>
                    <a-col
                        v-for='pivot in pivots'
                        :key='pivot.reportId'
                        cols='12'
                    >
                        <a-card outlined>
                            <a-card-title>
                                <a-skeleton-loader type='text' width='50%'></a-skeleton-loader>
                            </a-card-title>
                            <a-divider></a-divider>
                            <a-card-text style='min-height:150px'>
                                <a-skeleton-loader type='rect' width='100%' height='150px'></a-skeleton-loader>
                            </a-card-text>
                        </a-card>
                    </a-col>
                </template>
                <template v-else>
                    <a-col
                        v-for='pivot in pivots'
                        :key='pivot.reportId'
                        cols='12'
                    >
                        <a-card outlined :loading='loading'>
                            <a-card-title>
                                <a-icon
                                    left
                                    class='drag-handler'
                                >
                                    drag_indicator
                                </a-icon>
                                {{pivot.name}}
                                <a-spacer></a-spacer>
                                <a-tooltip v-if='showAddToReport' bottom>
                                    <template v-slot:activator='{ on }'>
                                        <a-btn
                                            icon
                                            color-icon='mono-grey-40'
                                            v-on='on'
                                            @click='showDashboard(pivot)'
                                        >
                                            <a-icon>
                                                mdi-view-grid-plus
                                            </a-icon>
                                        </a-btn>
                                    </template>
                                    {{$t('ADD_REPORT_TO_DASHBOARD')}}
                                </a-tooltip>
                                <a-btn icon color-icon='mono-grey-40' @click='editPivot(pivot)'>
                                    <a-icon>edit</a-icon>
                                </a-btn>
                                <a-btn icon color-icon='mono-grey-40' @click='deletePivot(pivot)'>
                                    <a-icon>delete</a-icon>
                                </a-btn>
                            </a-card-title>
                            <a-divider></a-divider>
                            <a-card-text style='min-height:150px'>
                                <data-table-report-pivot
                                    v-if='!loading'
                                    :_options='pivot.options'
                                    :_items='_items'
                                    :_edit-mode='false'
                                    :_show-banner='false'
                                    :_report-name='pivot.name'
                                />
                            </a-card-text>
                        </a-card>
                    </a-col>
                </template>
                <a-col v-if='!pivots.length && !loading' cols='12'>
                    <a-row align='center' justify='center'>
                        <a-img contain aspect-radioaspect-ratio='1' max-width='600px' :src='$_aura.getImage("empty-reports")' />
                    </a-row>
                    <a-row align='center' justify='center'>
                        <label>{{$t('NO_REPORTS')}}</label>
                    </a-row>
                </a-col>
            </draggable>
        </a-col>

        <data-table-report-form
            v-model='dialog'
            :_items='computedItems'
            :_command-name='_commandName'
            :_preference-key='_preferenceKey'
            :_edited-item='editedItem'
            :_command='_command'
            :_headers='_headers'
            :_all-headers='allHeaders'
            :_total-items='totalItems'
            :_loading-data='computedLoadingData'
            @save='load'
        />
        <a-dialog v-model='visibleDashboardDialog' max-width='500'>
            <a-card>
                <a-card-title>
                    {{$t('ADD_REPORT_TO_DASHBOARD')}}
                </a-card-title>
                <a-window v-model='window'>
                    <a-window-item>
                        <a-list class='px-4'>
                            <a-text-field v-model='search' :label='$t("SEARCH")' />
                            <a-list-item-group v-model='selected'>
                                <a-list-item
                                    v-for='(dashboard, index) in filteredDashboards'
                                    :key='index'
                                    :value='dashboard'
                                    @click='selected = dashboard'
                                >
                                    <a-list-item-content>
                                        <a-list-item-title>
                                            {{dashboard.NOME}}
                                        </a-list-item-title>
                                    </a-list-item-content>
                                </a-list-item>
                            </a-list-item-group>
                        </a-list>
                        <a-card-actions class='justify-end'>
                            <a-btn
                                color='mono-grey-40'
                                outlined
                                @click='visibleDashboardDialog = false'
                            >
                                {{$t('CANCEL')}}
                            </a-btn>
                            <a-btn
                                color='save'
                                :disabled='!selected'
                                @click='window++'
                            >
                                {{$t('CONTINUE')}}
                            </a-btn>
                        </a-card-actions>
                    </a-window-item>
                    <a-window-item>
                        <a-card-subtitle class='pb-0'>
                            {{$t('FILTERS')}}
                        </a-card-subtitle>
                        <a-card-text v-if='command' style='max-height:500px' class='overflow-y-auto pt-4'>

                            <a-command-filter
                                v-if='command.v2'
                                _mode='inline'
                                _group='all'
                                :_command.sync='command'
                            />
                            <a-dynamic-command-filter
                                v-else
                                filter-group='all'
                                exhibition-mode='inline'
                                :_inline-fields-size='1'
                                :_command='command'
                                :_filters='filters'
                                :_sidebar-props='{ _hideActions: true }'
                            />
                        </a-card-text>
                        <a-card-actions class='justify-end'>
                            <a-btn
                                color='mono-grey-40'
                                outlined
                                @click='window--'
                            >
                                {{$t('GO_BACK')}}
                            </a-btn>
                            <a-btn
                                color='save'
                                @click='addReportToDashboard'
                            >
                                {{$t('SAVE')}}
                            </a-btn>
                        </a-card-actions>
                    </a-window-item>
                </a-window>
            </a-card>
        </a-dialog>
    </a-row>
</template>

<script>
    import { GetUserConfigs, DeleteUserConfig, SaveUserConfig } from '@/components/core/services/request.ts';
    import { GetDashboards, SaveDashboard } from '@/pages/dashboard/services/request';
    import { REPORT_TYPES } from '@/pages/dashboard/services/consts.ts';
    import { v4 as uuidv4 } from 'uuid';
    import tableFunctions from '@/lib/aura-vue/util/mixins/table-functions';
    import { PREFERENCES_KEY as PAGE_CONSTRUCTOR_KEYS } from '@/pages/pageConstructor/services/consts';
    import { createCommand } from '@/util/dynamicForm/command';
    import { PREFERENCES_KEYS } from './services/consts';

    export default {
        name: 'DataTableReport',
        components: {
            DataTableReportForm: () => import('./DataTableReportForm'),
            DataTableReportPivot: () => import('@/components/core/dataTableReport/DataTableReportPivot'),
            draggable: () => import('vuedraggable'),
        },
        mixins: [tableFunctions],
        props: {
            _title: {
                type: String,
                default: undefined,
            },
            _commandName: {
                type: String,
                default: undefined,
            },
            _items: {
                type: Array,
                default: () => [],
            },
            _preferenceKey: {
                type: String,
                default: undefined,
            },
            _allHeaders: {
                type: Array,
                default: undefined,
            },
            _filters: {
                type: Object,
                default: undefined,
            },
            _command: {
                type: Object,
                default: null,
            },
            _headers: {
                type: Array,
                default: null,
            },
            _advancedFilters: {
                type: Object,
                default: () => ({}),
            },
        },
        data: function () {
            return {
                dialog: false,
                loading: false,
                pivots: [],
                pivotsOrder: [],
                editedItem: null,
                allHeaders: [],
                filters: undefined,
                visibleDashboardDialog: false,
                dashboards: undefined,
                selected: null,
                allReports: [],
                window: 0,
                command: null,
                newCommand: null,
                search: null,
                totalItems: [],
                loadingData: false,
                isPageConstructorTableCommand: false,
            };
        },
        computed: {
            preferenceName: function () {
                if (this._preferenceKey) {
                    return this._preferenceKey;
                }

                return this._commandName;
            },
            filteredDashboards: function () {
                if (!this.search || this.search === '') {
                    return this.dashboards;
                }
                return this.dashboards
                    .filter(dashboard => this.$lodash.toLower(dashboard.NOME)
                        .includes(this.$lodash.toLower(this.search)));
            },
            computedLoadingData: function () {
                return this.loadingData;
            },
            computedItems: function () {
                return this._items;
            },
            showAddToReport: function () {
                return this._command && Object.keys(this._command).length > 0;
            },
        },
        watch: {
            dialog: function (value) {
                if (!value) {
                    this.editedItem = null;
                }
            },
            '_items.length': 'load',
            selected: async function () {
                await this.setCommand();
            },
            visibleDashboardDialog: function (newVal) {
                if (newVal) {
                    this.search = '';
                }
            },
        },
        created: async function () {
            const _ = require('lodash');
            this.newCommand = _.cloneDeep(this._command);

            if (this.newCommand.paramsRequested?.dataPageSize) {
                delete this.newCommand.fields.dataPageSize;
                const resolve = await this.newCommand.callCommand();
                this._items = resolve.RAIZ.PONTOS_SERVICOS.PONTO_SERVICO;
                this.loadingData = true;
            } else {
                this.loadingData = true;
            }
            this.load();
        },
        methods: {
            load: async function () {
                this.filters = this._filters;
                // let loadingData = false;
                const _ = require('lodash');
                this.newCommand = _.cloneDeep(this._command);
                // if (this.newCommand.paramsRequested.dataPageSize) {
                //     delete this.newCommand.fields.dataPageSize;
                //     const resolve = await this.newCommand.callCommand();
                //     this._items = resolve.RAIZ.PONTOS_SERVICOS.PONTO_SERVICO;
                //     this.loadingData = true;
                // } else {
                //     this.loadingData = true;
                // }
                this.allHeaders = this._allHeaders;
                if (this.loadingData) {
                    try {
                        this.loading = true;
                        await this.setPivotOrder();
                        await this.setPivots();
                        setTimeout(() => {
                            this.loading = false;
                        }, 800);
                    } catch (error) {
                        this.loading = false;
                        throw new Error(error);
                    }
                }
            },
            setPivots: async function () {
                const command = new GetUserConfigs();
                const request = await command.callCommand({
                    name: `${PREFERENCES_KEYS.DATA_TABLE_PIVOT_REPORT}:${this.preferenceName}:*`,
                });

                const items = this.$util.getArrayByNode(request, command.node).map(item => {
                    const key = item.NOME;
                    const [, name] = key.split(':');
                    if (name === PAGE_CONSTRUCTOR_KEYS.PAGE_CONSTRUCTOR_TABLE_COMMAND) {
                        this.isPageConstructorTableCommand = true;
                    }

                    if (this.$util.isJsonString(item.VALOR)) {
                        return JSON.parse(item.VALOR);
                    }
                    return item.VALOR;
                });

                this.pivots = this.$lodash.orderBy(items, i => this.pivotsOrder.indexOf(i.reportId));
            },
            setPivotOrder: async function () {
                const command = new GetUserConfigs();

                const request = await command.callCommand({
                    name: `${PREFERENCES_KEYS.DATA_TABLE_PIVOT_REPORT_ORDER}:${this.preferenceName}`,
                });

                const item = this.$util.getObjectByNode(request, command.node);

                if (!item || !item.VALOR) {
                    return;
                }

                if (this.$util.isJsonString(item.VALOR)) {
                    item.VALOR = JSON.parse(item.VALOR);
                }

                this.pivotsOrder = item.VALOR;
            },
            updatePivotOrder: async function () {
                const command = new SaveUserConfig();
                const order = this.pivots.map(p => p.reportId);

                await command.callCommand(
                    {
                        name: `${PREFERENCES_KEYS.DATA_TABLE_PIVOT_REPORT_ORDER}:${this.preferenceName}`,
                        value: order,
                    },
                    { silent: true },
                );
            },
            editPivot: function (item) {
                this.editedItem = item;
                this.dialog = true;
            },
            deletePivot: async function (pivot) {
                const { value } = await this.$_aura.swalDelete();

                if (!value) {
                    return;
                }

                const command = new DeleteUserConfig();

                await command.callCommand({
                    name: pivot.reportId,
                });

                await this.load();
            },
            showDashboard: async function (item) {
                this.visibleDashboardDialog = true;
                const params = {
                    dashboardId: undefined,
                };
                const resolve = await new GetDashboards().callCommand(params);
                this.dashboards = resolve.RAIZ.DASHBOARDS.DASHBOARD;
                this.editedItem = item;
            },
            setCommand: async function () {
                if (this._command && this._command.v2 && this.isPageConstructorTableCommand) {
                    this.command = createCommand({
                        name: this._command.name,
                        node: this._command.node,
                        params: this._command.params,
                        processResponseData: this._command.processResponseData,
                        isPageConstructor: true,
                    });
                    return;
                }
                const module = await import('@/util/commands');
                const Commands = module.default;

                this.command = new Commands[this._commandName]();
            },
            addReportToDashboard: async function () {
                if (this.selected.RELATORIOS) {
                    this.allReports = JSON.parse(this.selected.RELATORIOS);
                }
                let filters = [];
                if (this.command?.v2 && this.isPageConstructorTableCommand) {
                    filters = this.command.getParams();
                    filters.SCRIPT_PARAMETERS = JSON.stringify(Object.fromEntries(
                        Object.entries(filters).map(([key, value]) => [key, value || null]),
                    ));
                } else if (Array.isArray(this.filters)) {
                    filters = this.filters.map(filter => ({
                        key: filter.name,
                        // eslint-disable-next-line exati/valid-underscore
                        value: filter._value,
                    }));
                } else if (typeof this.filters === 'object' && this.filters !== null) {
                    filters = Object.keys(this.filters).map(key => ({
                        key: this.filters[key].name,
                        // eslint-disable-next-line exati/valid-underscore
                        value: this.filters[key]._value,
                    }));
                }
                const report = {
                    NAME: this.editedItem.name,
                    REPORT_ID: this.editedItem.reportId,
                    TYPE: REPORT_TYPES.PIVOT,
                    commandName: this.editedItem.commandName,
                    FILTERS: filters,
                    options: this.editedItem.options,
                    w: 6,
                    h: 9,
                };

                if (report.options) {
                    const renName = report.options.rendererName;
                    if (renName === 'TABLE' || renName === this.$t('TABLE')) {
                        report.w = 12;
                    } else if (renName === 'LINE_CHART' || renName === this.$t('LINE_CHART')
                        || renName === 'BAR_CHART' || renName === this.$t('BAR_CHART')) {
                        report.w = 8;
                    } else if (renName === 'MULTIPLE_PIE_CHART' || renName === this.$t('MULTIPLE_PIE_CHART')
                        || renName === 'INDICATOR' || renName === this.$t('INDICATOR')) {
                        report.w = 6;
                    }
                }
                this.addReport(report);
                await new SaveDashboard().callCommand({
                    dashboardId: this.selected.ID_DASHBOARD,
                    originalDashboardId: this.selected.ID_DASHBOARD_ORIGINAL,
                    name: this.selected.NOME,
                    reports: this.allReports,
                });
                this.visibleDashboardDialog = false;
                this.window = 0;
                this.$util.openPage('Dashboard', {
                    props: {
                        _dashboardId: this.selected.ID_DASHBOARD,
                    },
                });
            },
            addReport: function (report) {
                this.allReports.push({
                    x: 0,
                    y: 0,
                    w: report.w || report.minW || 4,
                    h: report.h || report.minH || 6,
                    minW: report.minW || 4,
                    minH: report.minH || 2,
                    FONT_COLOR: report.FONT_COLOR || 'black',
                    BACKGROUND_COLOR: report.BACKGROUND_COLOR || 'white',
                    ...report,
                    i: uuidv4(),
                });
            },
        },
    };
</script>
