import { GetUserLogged, GetTranslationKeys } from '@components/core/app/services/request';
import Vue from 'vue';
import _ from 'lodash';
import menu from '@/api/menu';
import i18n from '@/i18n';
import store from '@/store/store';
import axios from 'axios';
import tgStore from '@/pages/remoteManagement/services/store';

const user = {
    getLoggedUser: async function () {
        try {
            const interfaceCode = 'GVUE';
            const result = await new GetUserLogged().callCommand({ interfaceCode: interfaceCode });
            const usu = Vue.prototype.$_aura.getArray(result.RAIZ.USUARIO_LOGADO)[0];

            const u = {
                name: usu.NOME_PESSOA,
                personId: usu.ID_PESSOA,
                login: usu.LOGIN,
                context: usu.CONTEXTO,
                email: usu.EMAIL_PESSOA,
                userId: usu.ID_USUARIO,
                isAdmin: parseInt(usu.ID_USUARIO, 10) === 1,
                isPrivilegedUser: usu.USUARIO_PRIVILEGIADO,
                imageUrl: await this.downloadUrlFile(`${Vue.prototype.$_aura.getHTTPUrl()}?CMD.COMMAND=ConsultarFotoPessoaProtegida&CMD.ID_PESSOA=${usu.ID_PESSOA}&parser=file&auth_token=${Vue.prototype.$_aura.getToken()}`),
                companyId: usu.ID_EMPRESA_USUARIA ? usu.ID_EMPRESA_USUARIA : null,
                companyOwnerId: usu.ID_DONO ? usu.ID_DONO : null,
                signatureId: usu.ID_ASSINATURA_MOIP ? usu.ID_ASSINATURA_MOIP : null,
                favoriteRegisters: [],
                preferences: usu.VALORES_PREFERENCIA_USUARIO,
                locale: usu.LOCALE,
                inactivityTime: usu.TEMPO_INATIVIDADE_LOGOUT,
                googleMapsAddressing: usu.ENDERECAMENTO_GOOGLEMAPS,
                countryCode: usu.COD_PAIS,
                streetViewEnabled: usu.STREET_VIEW_HABILITADO,
                permissions: usu.GRUPOS_PERMISSOES,
                isIoTHubEnabled: usu.IOTHUB_HABILITADO,
            };

            store.dispatch('changeLocaleAction', { locale: u.locale });

            const userMenus = Vue.prototype.$_aura.getArray(() => result.RAIZ.MENUS.MENU);
            const customModules = Vue.prototype.$_aura.getArray(() => result.RAIZ.MODULOS_VISUAIS.MODULO_VISUAL);
            const customReports = Vue.prototype.$_aura.getArray(() => result.RAIZ.RELATORIOS_CUSTOMIZADOS.RELATORIO_CUSTOMIZADO);

            const customMenus = customModules.concat(customReports);

            const initialMenu = _.cloneDeep(menu);

            customMenus.forEach(custom => {
                if (custom.MENU_VUE && custom.MENU_VUE.indexOf('{') === 0) {
                    const parsedCategory = JSON.parse(custom.MENU_VUE);
                    const selectedMenu = initialMenu.find(men => men.code === parsedCategory.option);
                    const category = selectedMenu.categories.findIndex(categ => categ.code === parsedCategory.category);

                    if (category === -1 && parsedCategory.category.indexOf('CUSTOM_') === 0) {
                        selectedMenu.categories.push({ code: parsedCategory.category, title: parsedCategory.category.substring('CUSTOM_'.length) });
                    }

                    if (custom.ID_MODULO_VISUAL) {
                        selectedMenu.items.push({
                            title: custom.DESC_MODULO_VISUAL,
                            component: 'PageConstructorDetails',
                            icon: selectedMenu.icon,
                            code: `custom_${custom.ID_MODULO_VISUAL}`,
                            menu: parsedCategory.option,
                            categories: parsedCategory.category,
                            props: { _constructorId: parseInt(custom.ID_MODULO_VISUAL, 10), _editMode: false },
                        });
                    } else if (custom.ID_RELATORIO_CUSTOMIZADO) {
                        selectedMenu.items.push({
                            title: custom.DESC_RELATORIO_CUSTOMIZADO,
                            component: 'CustomReportDetails',
                            icon: selectedMenu.icon,
                            code: `report_${custom.ID_RELATORIO_CUSTOMIZADO}`,
                            menu: parsedCategory.option,
                            categories: parsedCategory.category,
                            props: { _customReportId: parseInt(custom.ID_RELATORIO_CUSTOMIZADO, 10) },
                        });
                    }
                }
            });

            try {
                await this.loadTranslationKeys();
            } catch (e) {}


            // eslint-disable-next-line no-inner-declarations
            function clearMenu (menuAux, optionsMenu) {
                for (let i = 0; i < menuAux.length; i++) {
                    const men = menuAux[i];
                    let exists = false;
                    for (let j = 0; j < optionsMenu.length; j++) {
                        const userMenu = optionsMenu[j];
                        if (userMenu.COD_MENU === men.code) {
                            exists = true;
                            break;
                        }
                    }

                    if (men.items) {
                        clearMenu(men.items, optionsMenu);
                    } else if (!exists) {
                        menuAux.splice(i, 1);
                        i--;
                    }
                }

                for (let i = 0; i < menuAux.length; i++) {
                    const men = menuAux[i];
                    if (men.items && men.items.length === 0) {
                        menuAux.splice(i, 1);
                        i--;
                    }
                }
            }

            try {
                const request = await tgStore.actions.getAuthInformation();
                const { userType } = request.response.user;
                initialMenu[initialMenu.findIndex(item => item.code === 'remoteManagement')] = {
                    ...initialMenu[initialMenu.findIndex(item => item.code === 'remoteManagement')],
                    items: initialMenu[initialMenu.findIndex(item => item.code === 'remoteManagement')].items.filter(item => {
                        if (!item.allowed || userType === 'admin') {
                            return true;
                        }

                        if (userType === 'factory') {
                            return item.allowed === 'factory';
                        }

                        return false;
                    }),
                };
            } catch {}

            if (u.isAdmin || u.isPrivilegedUser) {
                u.menuOptions = initialMenu;
            } else {
                clearMenu(initialMenu, userMenus);

                u.menuOptions = initialMenu;
            }

            // eslint-disable-next-line no-inner-declarations
            function bindFavorites (menuOptions) {
                for (let i = 0; i < menuOptions.length; i++) {
                    const men = menuOptions[i];
                    men.favorite = false;
                    if (men.items) {
                        bindFavorites(men);
                    }
                }
            }

            bindFavorites(u.menuOptions);

            Vue.prototype.$_aura.setUser(u);
        } catch (e) {
            throw new Error(e);
        }
    },
    downloadUrlFile: async function (url) {
        const config = {
            method: 'GET',
            url: this.formatUrlDownloadFile(url),
            responseType: 'blob',
            headers: {
                Authorization: Vue.prototype.$_aura.getToken(),
            },
        };
        const req = await axios(config);

        return URL.createObjectURL(new Blob([req.data], { type: req.headers['content-type'] }));
    },
    formatUrlDownloadFile: function (url) {
        if (url.indexOf('auth_token') >= 0) {
            url = url.replace(Vue.prototype.$_aura.getHTTPUrl(), '');
            const urlParams = new URLSearchParams(url);
            urlParams.delete('auth_token');

            return `${Vue.prototype.$_aura.getHTTPUrl()}?${urlParams.toString()}`;
        }

        return url;
    },
    enableChat: async function (enable) {
        const interfaceCode = 'GVUE';
        const result = await new GetUserLogged().callCommand({ interfaceCode: interfaceCode });
        const usu = Vue.prototype.$_aura.getArray(result.RAIZ.USUARIO_LOGADO)[0];
        this.loadMovidesk(usu.NOME_PESSOA, usu.EMAIL_PESSOA, 'GVUE', usu.CONTEXTO, enable);
    },
    loadMovidesk: function (userName, userMail, version, cliente, enable) {
        if (enable) {
            movideskLogin({
                name: userName,
                email: userMail,
                organizationCodeReference: cliente,
                stayConnected: false,
                emptySubject: false,
                startChat: false, // Se o startChat for true, o aplicativo irá maximizar e a conversa irá inicializar automaticamente
            });
            document.getElementById('md-app-widget').style.display = 'block';
            document.getElementsByClassName('md-chat-widget-btn-wrapper')[0].click();
        } else {
            document.getElementsByClassName('md-chat-widget-btn-wrapper')[0].click();
            document.getElementById('md-app-widget').style.display = 'none';
        }
    },
    loadTranslationKeys: async function () {
        const interfaceCode = 'GVUE';
        const result = await new GetTranslationKeys().callCommand({ interfaceCode: interfaceCode });

        const keys = Vue.prototype.$_aura.getArray(() => result.RAIZ.KEYS.KEY);

        const currentLocale = i18n.messages[i18n.locale];

        keys.forEach(k => {
            if (!Vue.prototype.$util.isStringEmpty(k.TRIMMED_KEY) && !Vue.prototype.$util.isStringEmpty(k.VUE_TRANSLATION_CONTEXT)) {
                currentLocale[k.VUE_TRANSLATION_CONTEXT][k.TRIMMED_KEY] = k.VALUE;
            } else {
                currentLocale[k.KEY] = k.VALUE;
            }
        });

        i18n.setLocaleMessage(i18n.locale, currentLocale);
    },
};

export default user;
