<template>
    <a-row>
        <a-col style='height: 2.68em;' cols='1' class='d-flex justify-center'>
            <p>
                <span class='text-h6'>{{stepsCompleted}}</span>
                <span class='mono-grey-40--text'>/{{_quantity}}</span>
            </p>
        </a-col>
        <a-col style='height: 2.68em;' cols='11' class='d-flex flex-column-reverse justify-start pl-1'>
            <div class='d-flex'>
                <div
                    v-for="index in _quantity"
                    :key='index'
                    :class='["step", { "step-active": _stepsCompleted[index-1] }]'
                />
            </div>
        </a-col>
    </a-row>
</template>

<script>
    export default {
        name: 'AProgressSegment',
        components: {},
        props: {
            /**
             * 
             * @type {Number}
             * @default {undefined}
             * required: true
             */
            _quantity: {
                type: Number,
                default: 0,
                required: true,
            },
            /**
             * 
             * @type {Array}
             * default {[]}
             * required: true
             */
            _stepsCompleted: {
                type: Array,
                default: () => [],
                required: true,
            },
        },
        data: function () {
            return {};
        },
        computed: {
            stepsCompleted: function () {
                return this._stepsCompleted.filter(sc => sc).length;
            },
        },
        methods: {
            load: function () {},
        },
    };
</script>

<style  lang='scss' scoped>
    .step {
        flex: 1;
        height: 10px;
        background-color: var(--v-mono-grey-20-base);
        border-radius: 5px;
        margin-right: 5px;
    }

    .step:last-child {
        margin-right: 0;
    }

    .step-active {
        background-color: var(--v-exati-blue-main-base);
    }
</style>