import { extend } from 'vue-breakpoint-component';
export const PREFERENCES_KEY = {
    PAGE_CONSTRUCTOR_TABLE: 'PAGE_CONSTRUCTOR_TABLE',
    PAGE_CONSTRUCTOR_TABLE_COMMAND: 'PAGE_CONSTRUCTOR_TABLE_COMMAND',
};
export default {
    PREFERENCES_KEY: PREFERENCES_KEY,
};
export const COMPONENT_TYPES_WITH_VARIABLES = [
    'a-autocomplete',
];
export const DATA_TABLE_COMMAND_ICONS = [
    'default',
    'add',
    'delete',
    'edit',
    'export',
    'save',
    'search',
    'next',
    'previous',
    'check',
    'usercheck',
    'useruncheck',
    'excel',
    'pdf',
    'print',
    'uncheck',
    'view',
    'filter',
    'refresh',
    'copy',
    'warning',
    'clone',
    'ellipsisv',
    'ellipsish',
    'menu',
    'settings',
    'expandbottom',
    'expandtop',
    'ban',
    'calendar',
    'cellphone',
    'chat',
    'clock',
    'close',
    'download',
    'externallink',
    'fax',
    'file',
    'flag',
    'folder',
    'graph',
    'historic',
    'info',
    'map',
    'marker',
    'notification',
    'phone',
    'photo',
    'pin',
    'privacy',
    'star',
    'upload',
    'user',
    'video',
    'barcode',
    'qrcode',
    'keyboard',
    'globe',
    'tablesearch',
    'play',
    'lock',
    'usergroup',
    'migrate',
    'service',
    'alarm',
    'select',
    'unlink',
    'arrowup',
    'arrowdown',
    'send',
    'emailreceive',
    'currency',
    'splitarrow',
    'link',
    'refund',
    'scalebalance',
    'assign-user',
    'dollar-sign',
    'link',
    'report',
    'invoice',
    'open',
    'viewslash',
    'lightbulb',
    'truck-check',
    'certificate',
    'certificate-file',
];
export const BREAKPOINT_KEYS = ['xxl', 'xl', 'lg', 'md', 'sm', 'xs'];
export const BREAKPOINT_VALUES = {
    lg: 1200,
    md: 996,
    sm: 768,
    xs: 480,
    xxs: 0,
};
export const BREAKPOINT_KEYS_MAP = {
    xlarge: 'xl',
    large: 'lg',
    medium: 'md',
    small: 'sm',
    xsmall: 'xs',
};
export const BREAKPOINT_COLS = {
    xl: 12,
    lg: 12,
    md: 10,
    sm: 6,
    xs: 4,
    xxs: 2,
};
export const GRID_LAYOUT_POSITION_KEYS = ['minW', 'minH', 'maxW', 'maxH', 'x', 'y', 'w', 'h', 'i'];
const config = {
    breakpoints: {
        small: '(min-width: 768px)',
        medium: '(min-width: 996px)',
        large: '(min-width: 1200px)',
        xlarge: '(min-width: 1920px)',
    },
    debounceTime: 50,
    experimental: false,
};
export const REPORT_TYPE = 4;
const { VShowAt, VHideAt, VBreakpoint } = extend(config);
export { VShowAt, VHideAt, VBreakpoint };
