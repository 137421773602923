const paths = [
    {
        path: '/attribute-schema',
        name: 'AttributeSchemaList',
        title: 'ATTRIBUTE_SCHEMA_LIST',
        menu: 'registration',
        icon: 'mdi-file-tree',
        component: () => import(/* webpackChunkName: "attributeSchema" */ /* webpackMode: "lazy" */ '@/pages/attributeSchema/AttributeSchemaList.vue'),
    },
    {
        path: '/attribute-schema-details',
        name: 'AttributeSchemaDetails',
        title: 'ATTRIBUTE_SCHEMA_DETAILS',
        favCategory: 'registration',
        component: () => import(/* webpackChunkName: "attributeSchema" */ /* webpackMode: "lazy" */ '@/pages/attributeSchema/AttributeSchemaDetails.vue'),
    },
    {
        path: '/attribute-list',
        name: 'AttributeList',
        title: 'ATTRIBUTE_LIST',
        component: () => import(/* webpackChunkName: "attributeSchema" */ /* webpackMode: "lazy" */ '@/pages/attributeSchema/AttributeList.vue'),
    },
    {
        path: '/attribute-details',
        name: 'AttributeDetails',
        title: 'ATTRIBUTE_DETAILS',
        component: () => import(/* webpackChunkName: "attributeSchema" */ /* webpackMode: "lazy" */ '@/pages/attributeSchema/AttributeDetails.vue'),
    },
    {
        path: '/attribute-value-details',
        name: 'AttributeValueDetails',
        title: 'ATTRIBUTE_VALUE_DETAILS',
        component: () => import(/* webpackChunkName: "attributeSchema" */ /* webpackMode: "lazy" */ '@/pages/attributeSchema/AttributeValueDetails.vue'),
    },
    {
        path: '/attribute-history',
        name: 'AttributeHistory',
        title: 'ATTRIBUTE_HISTORY',
        component: () => import(/* webpackChunkName: "attributeSchema" */ /* webpackMode: "lazy" */ '@/pages/attributeSchema/AttributeHistory.vue'),
    },
    {
        path: '/attribute-schema-category-list',
        name: 'AttributeSchemaCategoryList',
        title: 'ATTRIBUTE_SCHEMA_CATEGORY_LIST',
        icon: 'fa fa-briefcase',
        menu: 'registration',
        component: () => import(/* webpackChunkName: "attributeSchemaCategory" */ /* webpackMode: "lazy" */ '@/pages/attributeSchema/AttributeSchemaCategoryList.vue'),
    },
    {
        path: '/attribute-schema-category-general',
        name: 'AttributeSchemaCategoryGeneral',
        title: 'ATTRIBUTE_SCHEMA_CATEGORY_LIST',
        component: () => import(/* webpackChunkName: "attributeSchemaCategory" */ /* webpackMode: "lazy" */ '@/pages/attributeSchema/AttributeSchemaCategoryGeneral.vue'),
    },
    {
        path: '/attribute-configuration',
        name: 'AttributeConfiguration',
        title: 'ATTRIBUTE_CONFIGURATION',
        component: () => import(/* webpackChunkName: "attributeSchemaCategory" */ /* webpackMode: "lazy" */ '@/pages/attributeSchema/AttributeConfiguration.vue'),
    },
    {
        path: '/attribute-min-max-values',
        name: 'AttributeMinMaxValues',
        title: 'ALLOWED_ATTRIBUTE_VALUES',
        component: () => import(/* webpackChunkName: "attributeSchemaCategory" */ /* webpackMode: "lazy" */ '@/pages/attributeSchema/AttributeMinMaxValues.vue'),
    },
    {
        path: '/attribute-conditions-config',
        name: 'AttributeConditionsConfig',
        title: 'ATTRIBUTE_CONDITIONS_CONFIG',
        component: () => import(/* webpackChunkName: "attributeSchemaCategory" */ /* webpackMode: "lazy" */ '@/pages/attributeSchema/AttributeConditionsConfig.vue'),
    },
    {
        path: '/attribute-schema-edit-history',
        name: 'AttributeSchemaEditHistory',
        title: '/ATTRIBUTE_SCHEMA_EDIT_HISTORY',
        component: () => import(/* webpackChunkName: "maintenance" */ /* webpackMode: "lazy" */ '@/pages/attributeSchema/AttributeSchemaEditHistory.vue'),
    },
    {
        path: '/attribute-schema-person-role',
        name: 'AttributeSchemaPersonRoleList',
        icon: 'fa fa-briefcase',
        menu: 'registration',
        title: 'ROLE_PERSON_ATTRIBUTE_SCHEMA',
        component: () => import(/* webpackChunkName: "maintenance" */ /* webpackMode: "lazy" */ '@/pages/attributeSchema/AttributeSchemaPersonRoleList.vue'),
    },
    {
        path: '/attribute-schema-person-role-details',
        name: 'AttributeSchemaPersonRoleDetails',
        component: () => import(/* webpackChunkName: "maintenance" */ /* webpackMode: "lazy" */ '@//pages/attributeSchema/AttributeSchemaPersonRoleDetails.vue'),
    },
    {
        path: '/asset-grouped-by-attributes',
        name: 'AssetGroupedByAttributes',
        component: () => import(/* webpackChunkName: "maintenance" */ /* webpackMode: "lazy" */ '@/pages/attributeSchema/AssetsGroupedByAttributes.vue'),
    },
    {
        path: '/json-wizard-attribute-schema',
        name: 'JsonWizardAttributeSchema',
        component: () => import(/* webpackChunkName: "maintenance" */ /* webpackMode: "lazy" */ '@//pages/attributeSchema/JsonWizardAttributeSchema.vue'),
    },
];

export default paths;
