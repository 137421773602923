<template>
    <div>
        <a-autocomplete
            v-model='valueLazy'
            :entity='null'
            :loading='loading'
            v-bind='$attrs'
            :items='dataProvider'
            :filter='customFilterFunction'
            :item-value='$attrs["item-value"] ? $attrs["item-value"] : entity.ID_FIELD_NAME'
            :item-text='$attrs["item-text"] ? $attrs["item-text"] : entity.DESCRIPTION_FIELD_NAME'
            :search-input.sync='searchComputed'
            :value-comparator='compareItems'
            v-on='$listeners'>
            <!-- eslint-disable-next-line -->
            <slot v-for='slot in Object.keys($slots)' v-if='slot !== "prepend-inner"' :slot='slot' :name='slot' />
            <!-- eslint-disable-next-line -->
            <template v-for='slot in Object.keys($scopedSlots)' v-if='slot !== "prepend-inner"' :slot='slot' slot-scope='scope'>
                <slot :name='slot' v-bind='scope' />
            </template>
            <template v-slot:prepend-inner>
                <slot name='prepend-inner' />
                <div v-if='entity.EDIT_DIALOG && !$_aura.isEmpty(entity.EDIT_DIALOG.page)'>
                    <a-icon :color='iconColor' @click='openEditDialog($editDialog)'>
                        {{valueLazy ? 'fa-edit' : 'mdi-plus'}}
                    </a-icon>
                </div>
                <div v-else-if='edit'>
                    <a-icon :color='iconColor' @click='$emit("edit", { reload: refreshProvider })'>
                        {{valueLazy ? 'fa-edit' : 'mdi-plus'}}
                    </a-icon>
                </div>
            </template>
            <template v-slot:append>
                <slot name='append' />
            </template>
            <template v-slot:append-outer>
                <slot name='append-outer' />
            </template>
            <template v-slot:no-data>
                <div class='ml-2 mr-2'>{{minToSearch > 0 ? (hasMinToSearch ? $t('NO_DATA_AVAILABLE') : $t('TYPE_AT_LEAST_MIN_TO_SEARCH', {min: minToSearch})) : $t('NO_DATA_AVAILABLE')}}</div>
            </template>
        </a-autocomplete>
    </div>
</template>

<script>
    import searchDataProvider from '../../util/mixins/search-data-provider';

    export default {
        name: 'AAutocompleteCustom',
        inheritAttrs: false,
        mixins: [searchDataProvider],
        computed: {
            iconColor: function () {
                if (this._iconColor) {
                    return this._iconColor;
                }
                return this.valueLazy ? "success" : "primary"
            },
        },
    };
</script>
