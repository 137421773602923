import enUS from './locale/en/all.json';
import ptBr from './locale/pt/all.json';
import es from './locale/es/all.json';

import enUSTableHeader from './locale/en/tableHeaders.json';
import ptBrableHeader from './locale/pt/tableHeaders.json';
import esTableHeader from './locale/es/tableHeaders.json';

enUS.TABLE_HEADER = enUSTableHeader;
ptBr.TABLE_HEADER = ptBrableHeader;
es.TABLE_HEADER = esTableHeader;

export default {
  // eslint-disable-next-line camelcase
  en_US: enUS,
  // eslint-disable-next-line camelcase
  pt_BR: ptBr,
  es: es,
};
