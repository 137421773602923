import axios from 'axios';
import lodash from 'lodash';
import aura from '@/lib/aura-vue/aura/aura';
import util from '../util';
export function createCommand(options) {
    const defaultParams = {
        'CMD.COMMAND': options.name,
        parser: 'json',
        auth_token: aura.getToken(),
    };
    // create value property if not exist to guarantee reactivity
    const commandParams = Object.entries(options.params || {})
        .reduce((all, [name, param]) => ({
        ...all,
        [name]: {
            value: null,
            ...param,
        },
    }), {});
    /**
     * Get current values of params
     * @param params
     * @returns GuiaResponse
     */
    function getParams(mode = 'normal', parserValues = true) {
        return Object.entries(commandParams)
            .reduce((all, [key, param]) => {
            let name = param.name || key;
            let { value } = param;
            if (parserValues && param.parser) {
                value = param.parser(value);
            }
            if (mode === 'server' && !param.noCmdPrefix) {
                name = `CMD.${name}`;
            }
            return {
                ...all,
                [name]: value,
            };
        }, {});
    }
    function updateParam(name, data) {
        if (!commandParams[name]) {
            return;
        }
        commandParams[name] = lodash.merge(commandParams[name], data);
    }
    /**
     * Update all param values of command
     * @param params
     */
    function setParams(params) {
        Object.entries(params).forEach(([name, value]) => {
            updateParam(name, { value: value });
        });
    }
    /**
     * Convert normal params object to server command object
     * @param params
     * @returns GuiaResponse
     */
    function convertParamsToServerMode(params, parserValues = true) {
        return Object.entries(commandParams)
            .reduce((all, [key, param]) => {
            let name = param.name || key;
            let value = params[key] || param.value;
            if (parserValues && param.parser) {
                value = param.parser(value);
            }
            if (!param.noCmdPrefix) {
                name = `CMD.${name}`;
            }
            return {
                ...all,
                [name]: value,
            };
        }, {});
    }
    /** */
    function processResponseData(data) {
        if (options.processResponseData) {
            return options.processResponseData(data);
        }
        return data;
    }
    /**
     * Execute request to the server using params in server format
     * @param serverParams
     * @returns GuiaResponse
     */
    async function requestServer(serverParams, showMessages) {
        const form = new FormData();
        const allParams = { ...defaultParams, ...serverParams };
        Object.entries(allParams)
            .filter(([, value]) => value !== null && value !== undefined)
            .forEach(([name, value]) => form.append(name, value));
        let { data } = await axios.post(aura.getHTTPUrl(), form);
        data = processResponseData(data);
        const errors = lodash.get(data, 'RAIZ.MESSAGES.ERRORS', []);
        const infos = lodash.get(data, 'RAIZ.MESSAGES.INFORMATIONS', []);
        const warnings = lodash.get(data, 'RAIZ.MESSAGES.WARNINGS');
        if (showMessages || showMessages === undefined) {
            errors.forEach((error) => {
                aura.showErrorMessage(error);
            });
            infos.forEach((info) => {
                aura.showSuccessMessage(info);
            });
            warnings.forEach((warning) => {
                aura.showWarningMessage(warning);
            });
        }
        return data;
    }
    /**
     * Execute request to the server using params in normal format
     * @param params
     * @returns GuiaResponse
     */
    function request(params, showMessages) {
        const serverParams = convertParamsToServerMode(params);
        return requestServer({ ...defaultParams, ...serverParams }, showMessages);
    }
    /**
     * Convert a GuiaResponse to an array using command node
     * @param data
     * @returns items[]
     */
    function convertResponseToArray(data) {
        return util.getArrayByNode(data, options.node);
    }
    /**
     * Convert a GuiaResponse to an object using command node
     * @param data
     * @returns item
     */
    function convertResponseToObject(data) {
        return util.getObjectByNode(data, options.node);
    }
    /**
     * Checks if the command is from a page constructor table
     * @returns boolean
     */
    function isPageConstructor() {
        return options.isPageConstructor;
    }
    return {
        name: options.name,
        v2: true,
        defaultParams: defaultParams,
        params: commandParams,
        getParams: getParams,
        setParams: setParams,
        updateParam: updateParam,
        convertParamsToServerMode: convertParamsToServerMode,
        request: request,
        requestServer: requestServer,
        processResponseData: processResponseData,
        convertResponseToArray: convertResponseToArray,
        convertResponseToObject: convertResponseToObject,
        isPageConstructor: isPageConstructor,
    };
}
export default {
    createCommand: createCommand,
};
