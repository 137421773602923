export default function issueStatus (provider) {
    return new Promise((resolve) => {
        const resultProvider = [];

        provider.forEach((item) => {
            resultProvider.push({
 ID_HORARIO_TRABALHO: item.ID_HORARIO_TRABALHO, DESC_HORARIO_TRABALHO: item.NOME ? `${item.HORA_INICIO} - ${item.HORA_INICIO_INTERVALO} | ${item.HORA_FIM_INTERVALO} - ${item.HORA_FIM} (${item.NOME})` : `${item.HORA_INICIO} - ${item.HORA_INICIO_INTERVALO} | ${item.HORA_FIM_INTERVALO} - ${item.HORA_FIM}`, COLOR: item.COR, BORDER_COLOR: item.COR_BORDA,
});
        });

        resolve(resultProvider);
    });
}
