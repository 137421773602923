<script>
    import { StaticEntity } from '@inputs/entity.ts';
    export default {
        name: 'ASelect',
        functional: true,
        render: function (createElement, { data, children }) {
            data.attrs = data.attrs || {};
            data.attrs.outlined = Object.keys(data.attrs).includes('outlined') ? data.attrs.outlined : true;
            data.attrs.dense = Object.keys(data.attrs).includes('dense') ? data.attrs.dense : true;

            if(!Object.keys(data.attrs).includes('entity') && data.attrs.multiple && data.attrs.items.length > 5){
                data.attrs.entity = new StaticEntity({
                    fieldNameId: 'value',
                    descriptionField: 'text',
                    staticProvider: data.attrs.items,
                });
            }

            if (data.attrs.entity) {
                data.attrs.clearable = Object.keys(data.attrs).includes('clearable') ? data.attrs.clearable : true;
                return createElement('ASelectCustom', data, children);
            }

            return createElement('ASelectDefault', data, children);
        },
    };
</script>
