<template>
    <div class='custom-input'>
        <label for='color-picker' class='custom-input__label mono-grey-60--text'>
            {{$t('COLOR')}}
        </label>
        <div id='color-picker' class='custom-input__container'>
            <a-menu
                :close-on-content-click='false'
                max-width='200'
                offset-y
            >
                <template v-slot:activator='{ on, attrs }'>
                    <a-row v-bind='attrs' class='d-flex justify-center' v-on='on'>
                        <div
                            v-if='_borderSelector'
                            cols='auto'
                            class='color-circle-wrapper-activator mx-1 pa-0'
                            style='background-color: transparent;'
                        >
                            <div
                                class='color-circle'
                                :style='{ border: `3px solid ${selectedColors.border}` }'
                                style='width: 25px; height: 25px'
                            ></div>
                        </div>

                        <div
                            v-if='_backgroundSelector'
                            cols='auto'
                            class='color-circle-wrapper-activator mx-1 pa-0'
                        >
                            <div
                                class='color-circle'
                                :style='{ backgroundColor: selectedColors.background }'
                                style='width: 25px; height: 25px;'
                            ></div>
                        </div>

                        <a-col cols='auto' class='pa-1 align-content-center'>
                            <a-icon>mdi-menu-down</a-icon>
                        </a-col>
                    </a-row>
                </template>

                <a-card>
                    <a-card-text class='px-6 py-4'>
                        <template v-if='_borderSelector'>
                            <h3 class='mb-3 text-body-1 black--text' style='margin-left: -10px;'>
                                {{$t('EDGE')}}
                            </h3>
                            <a-row>
                                <div
                                    v-for='(color, index) in borderColors'
                                    :key='index'
                                    class='color-circle-wrapper'
                                    :style='{
                                        backgroundColor: selectedColors.border === color ? "var(--v-mono-grey-5-base)" : "transparent"
                                    }'
                                    @click='selectBorderColor(color)'
                                >
                                    <div
                                        class='color-circle'
                                        :style='{ border: `3px solid ${color}` }'
                                    ></div>
                                </div>

                                <a-col cols='12' class='px-1 py-1'>
                                    <a-menu offset-y :close-on-content-click='false'>
                                        <template v-slot:activator='{ on }'>
                                            <a-btn
                                                class='px-0 text-body-1 plain-style'
                                                v-on='on'
                                            >
                                                <a-icon small class='pb-1 pr-1'>
                                                    mdi-palette-outline
                                                </a-icon>
                                                <span v-if='borderColors.includes(selectedColors.border)'>
                                                    {{$t('CUSTOMIZE_COLOR')}}
                                                </span>
                                                <span v-else class='d-flex align-center'>
                                                    {{$t('COLOR')}}:
                                                    <div
                                                        class='mx-2 color-circle'
                                                        :style='{ border: `3px solid ${selectedColors.border}` }'
                                                    ></div>
                                                </span>
                                            </a-btn>
                                            <a-btn v-show='!borderColors.includes(selectedColors.border)' text icon class='pa-0'>
                                                <a-icon
                                                    color='mono-grey-40'
                                                    @click='setDefaultBorderColor'
                                                >
                                                    mdi-close
                                                </a-icon>
                                            </a-btn>
                                        </template>
                                        <a-color-picker
                                            v-model='selectedColors.border'
                                        />
                                    </a-menu>
                                </a-col>
                            </a-row>
                        </template>

                        <template v-if='_backgroundSelector'>
                            <p class='mb-3 text-body-1 black--text' style='margin-left: -10px;'>
                                {{$t('BACKGROUND')}}
                            </p>
                            <a-row>
                                <div
                                    v-for='(color, index) in backgroundColors'
                                    :key='index'
                                    class='color-circle-wrapper'
                                    :style='{
                                        backgroundColor: selectedColors.background === color ? "var(--v-mono-grey-5-base)" : "transparent"
                                    }'
                                    @click='selectBackgroundColor(color)'
                                >
                                    <div
                                        class='color-circle'
                                        :style='{ backgroundColor: color}'
                                    ></div>
                                </div>

                                <a-col cols='12' class='px-1 py-1'>
                                    <a-menu offset-y :close-on-content-click='false'>
                                        <template v-slot:activator='{ on }'>
                                            <a-btn
                                                class='px-0 text-body-1 plain-style'
                                                v-on='on'
                                            >
                                                <a-icon small class='pb-1 pr-1'>
                                                    mdi-palette-outline
                                                </a-icon>
                                                <span v-if='backgroundColors.includes(selectedColors.background)'>
                                                    {{$t('CUSTOMIZE_COLOR')}}
                                                </span>
                                                <span v-else class='d-flex align-center'>
                                                    {{$t('COLOR')}}:
                                                    <div
                                                        class='mx-2 color-circle'
                                                        :style='{ backgroundColor: selectedColors.background }'
                                                    ></div>
                                                </span>
                                            </a-btn>
                                            <a-icon
                                                v-show='!backgroundColors.includes(selectedColors.background)'
                                                color='mono-grey-40'
                                                @click='setDefaultBackgroundColor'
                                            >
                                                mdi-close
                                            </a-icon>
                                        </template>
                                        <a-color-picker
                                            mode='hexa'
                                            v-model='selectedColors.background'
                                            :hide-inputs='_hideInputs'
                                            :class='{ "no-alpha": _hideAlpha }'
                                        />
                                    </a-menu>
                                </a-col>
                            </a-row>
                        </template>
                    </a-card-text>
                </a-card>
            </a-menu>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AColorInput',
        components: {
        },
        props: {
            /**
             * Show border color selector
             * @type {Boolean}
             * @default {true}
             */
            _borderSelector: {
                type: Boolean,
                default: true,
            },
            /**
             * Show background color selector
             * @type {Boolean}
             * @default {true}
             */
            _backgroundSelector: {
                type: Boolean,
                default: true,
            },
            /**
             * Hide alpha slider to background
             * @type {Boolean}
             * @default {true}
             */
             _hideAlpha: {
                type: Boolean,
                default: false,
            },
            /**
             * Hide inputs for background oclor picker
             * @type {Boolean}
             * @default {true}
             */
             _hideInputs: {
                type: Boolean,
                default: false,
            },
        },
        data: function () {
            return {
                borderColors: [
                    '#0A64FF', '#6600CC', '#00bbcc', '#4eb8b0',
                    '#F9A726', '#EF5549', '#FF00FF', '#999999',
                ],
                backgroundColors: [],
                selectedColors: {
                    background: undefined,
                    border: undefined,
                },
            };
        },
        watch: {
            selectedColors: {
                handler: function (newColors) {
                    this.$emit('save', newColors);
                },
                deep: true,
            },
        },
        mounted: function () {
            this.load();
        },
        methods: {
            load: function () {
                this.backgroundColors = this.borderColors.map(color => color + "30"); 
                this.selectedColors.background = this.backgroundColors[0];
                this.selectedColors.border = this.borderColors[0];
                this.$emit('save', this.selectedColors);
            },
            setSelectedColors: function (newColors) {
                if (!newColors.background) {
                    newColors.background = this.backgroundColors[0];
                }
                if (!newColors.border) {
                    newColors.border = this.borderColors[0];
                }

                if ((newColors.background.length === 9 || newColors.background.length === 7) && !newColors.background.endsWith('30')) {
                    newColors.background = newColors.background.substring(0, 7) + "30";
                }

                this.selectedColors.background = newColors.background;
                this.selectedColors.border = newColors.border;
                this.$emit('save', this.selectedColors);
            },
            selectBorderColor: function (color) {
                this.selectedColors.border = color;
            },
            selectBackgroundColor: function (color) {
                this.selectedColors.background = color;
            },
            setDefaultBorderColor: function () {
                this.selectedColors.border = this.borderColors[0];
            },
            setDefaultBackgroundColor: function () {
                this.selectedColors.background = this.backgroundColors[0];
            },
        },
    };
</script>

<style lang='scss' scoped>
    .color-circle {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        cursor: pointer;
    }

    .color-circle-wrapper {
        width: 25%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 7px 0px;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    .color-circle-wrapper-activator {
        height: 100%;
        display: flex;
        align-items: center;
    }

    .custom-input {
        position: relative;
        display: inline-block;
        width: 100%;
    }

    .custom-input__label {
        background-color: white;
        position: absolute;
        padding: 0 4px;
        top: -5px;
        left: 12px;
        height: 9px;
        font-size: 12px;
        display: flex;
        align-items: center;
    }

    .custom-input__container {
        height: 40px;
        display: flex;
        border: var(--v-mono-grey-40-base) 1px solid;
        border-radius: 5px;
        cursor: pointer;

        &:hover {
            border-color: black;
        }
    }

    .plain-style {
        background-color: transparent !important;

        &:hover {
            color: var(--v-mono-grey-50-base);
        };

        &:hover::before {
            opacity: 0 !important;
            background-color: transparent !important;
        };
    }
</style>

<style lang="scss">
    .no-alpha {
        .v-color-picker__controls {
            .v-color-picker__preview {
            .v-color-picker__sliders {
                .v-color-picker__alpha {
                display: none;
                }
            }
            }
            .v-color-picker__edit {
                .v-color-picker__input:last-child {
                    display: none;
                }
            }
        }
    }
</style>
