const paths = [
    {
        path: '/android-base-generation',
        name: 'AndroidBaseGeneration',
        title: 'ANDROID_BASE_GENERATION',
        icon: 'android',
        menu: 'registration',
        component: () => import(/* webpackChunkName: "android" */ /* webpackMode: "lazy" */ '@/pages/android/AndroidBaseGeneration.vue'),
    },
    {
        path: '/android-app',
        name: 'AndroidApp',
        title: 'ANDROID_APP',
        icon: 'android',
        menu: 'registration',
        component: () => import(/* webpackChunkName: "android" */ /* webpackMode: "lazy" */ '@/pages/android/AndroidListVersions.vue'),
    },
    {
        path: '/android-app-clients',
        name: 'AndroidAppClients',
        title: 'ANDROID_APP',
        component: () => import(/* webpackChunkName: "android" */ /* webpackMode: "lazy" */ '@/pages/android/AndroidAppClientsExati.vue'),
    },
    {
        path: '/android-app-history-update',
        name: 'AndroidHistoryUpdate',
        title: 'ANDROID_APP',
        component: () => import(/* webpackChunkName: "android" */ /* webpackMode: "lazy" */ '@/pages/android/AndroidHistoryUpdate.vue'),
    },
    {
        path: '/android-app-history-active-version',
        name: 'ActiveAndroidVersionHistory',
        title: 'ANDROID_APP_HISTORY',
        component: () => import(/* webpackChunkName: "android" */ /* webpackMode: "lazy" */ '@/pages/android/ActiveAndroidVersionHistory.vue'),
    },
];

export default paths;
