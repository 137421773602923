<template>
    <a-expansion-panel class='panel rounded-border'>
        <a-expansion-panel-header :expand-icon='_hideExpandIcon ? "" : "mdi-chevron-down"'>
            <a-row no-gutters>
                <a-col cols='12' class='py-1 d-flex align-center'>
                    <a-col
                        v-if='_complete === undefined'
                        cols='auto'
                        class='pa-0 mr-4 numeric'
                    >
                        <a-icon size='25'>
                            {{`mdi-numeric-${_step}`}}
                        </a-icon>
                    </a-col>
                    <a-icon v-else-if='_complete' size='25' color='green' class='mr-4'>
                        mdi-check-circle
                    </a-icon>
                    <a-icon v-else size='25' color='red' class='mr-4'>
                        mdi-close-circle
                    </a-icon>
                    <h3 class='pt-1 font-weight-regular dark-text align-content-center mr-6'>
                        {{_name}}
                    </h3>
                    <slot name='header' />
                </a-col>
            </a-row>
        </a-expansion-panel-header>

        <a-expansion-panel-content>
            <a-divider />
            <slot />
        </a-expansion-panel-content>
    </a-expansion-panel>
</template>

<script>
    export default {
        name: 'AExpansionPanelStep',
        components: {},
        props: {
            /**
             * Step number
             * @type {Number}
             * @default {0}
             * required: true
             */
            _step: {
                type: Number,
                default: 0,
                required: true,
            },
            /**
             * Step name
             * @type {String}
             * default {""}
             * required: true
             */
            _name: {
                type: String,
                default: '',
                required: true,
            },
            /**
             * Indicates whether the step is complete
             * @type {Boolean}
             * default {undefined}
             */
            _complete: {
                type: Boolean,
                default: undefined,
            },
            /**
             * Hide the expand icon
             * @type {Boolean}
             * default {false}
             */
            _hideExpandIcon: {
                type: Boolean,
                default: false,
            },
        },
        data: function () {
            return {};
        },
        methods: {
            load: function () {},
        },
    };
</script>

<style  lang='scss' scoped>
    .rounded-border {
        border-radius: 8px !important;
    }

    .panel::before {
        box-shadow: 0px 0px 0.8px 0.8px var(--v-mono-grey-10-base);
    }
    .panel {
        box-shadow: 0px 0px 0.8px 0.8px var(--v-mono-grey-10-base);
    }
    .v-expansion-panel:not(:first-child):after {
        border-top: none;
    }

    .numeric {
        background-color: var(--v-exati-blue-light-base);
        border-radius: 30px;
        height: 25px;
        width: 25px;
    }
</style>