<template>
    <v-select v-model='selected' v-bind='$attrs' v-on='$listeners' @loaded='loaded'  :no-data-text='$t("NO_DATA_AVAILABLE")'>
        <slot v-for='slot in Object.keys($slots)' :slot='slot' :name='slot' />
        <template v-for='slot in Object.keys($scopedSlots)' :slot='slot' slot-scope='scope'>
            <slot :name='slot' v-bind='scope' />
        </template>
        <template v-if='_prependItemSelectAll && multipleComp' v-slot:prepend-item>
            <a-list-item ripple @click='toggleSelectAll'>
                <a-list-item-action>
                    <a-icon :color='valueComp.length > 0 ? "primary" : ""'>
                        {{iconAll}}
                    </a-icon>
                </a-list-item-action>
                <a-list-item-content>{{$t('ALL')}}</a-list-item-content>
            </a-list-item>
            <a-divider />
        </template>
        <template v-slot:append>
            <slot name='append' />
        </template>
        <template v-slot:append-outer>
            <slot name='append-outer' />
        </template>
        <template v-if='multiple' v-slot:selection='{ item, index }'>
            <span v-if='index === 0'>{{ item[itemText] }}</span>
            <span v-if='selected.length > 1 && index === 1'>, ...</span>
        </template>
    </v-select>
</template>

<script>
    import selectAllProvider from '../../util/mixins/select-all-provider';

    export default {
        name: 'ASelectDefault',
        mixins: [selectAllProvider],
        computed: {
            itemText() {
                return this.$attrs['item-text'];
            },
            multiple() {
                return this.$attrs.multiple !== undefined;
            },
        },
    };
</script>