<template>
    <div>
        <a-row no-gutters align='center'>
            <a-col v-for='(status, index) in _statusList' :key='status[_statusKey]' cols='auto'>
                <a-row no-gutters align='center'>
                    <a-col cols='auto' class='ma-0'>
                        <a-tooltip bottom>
                            <template v-slot:activator='{ on }'>
                                <a-hover v-slot:default='{ hover }'>
                                    <a-icon
                                        v-if='iconStatus(status)'
                                        :size='hover ? _stepIconSize + 10 : _stepIconSize'
                                        color="exati-blue-main"
                                        style='z-index: 2;'
                                        class='mx-2'
                                        v-on='on'
                                    >
                                        mdi mdi-check-circle
                                    </a-icon>
                                    <a-icon
                                        v-else
                                        :size='hover ? _stepIconSize + 10 : _stepIconSize'
                                        color="mono-grey-10"
                                        style='z-index: 2;'
                                        class='mx-2'
                                        v-on='on'
                                    >
                                        mdi mdi-circle-outline
                                    </a-icon>
                                </a-hover>
                            </template>
                            {{status[_statusTitle]}}
                        </a-tooltip>
                    </a-col>
                    <a-col v-if='index < _statusList.length - 1' cols='auto' class='ma-0'>
                        <a-progress-linear
                            :style='`width: ${_lineWidth}px; border-radius: 8px;`'
                            :value='_currentStatusId > status[_statusKey] ? 100 : 0'
                            :indeterminate='_currentStatusId === status[_statusKey] && _nextInProgress'
                            color='exati-blue-main'
                            background-color='mono-grey-10'
                            :height='_lineHeight'
                        />
                    </a-col>
                </a-row>
            </a-col>
        </a-row>
    </div>
</template>

<script>
    export default {
        name: 'AProgressStepper',
        props: {
            /**
             * Status list for the desired entity
             * @type {Array}
             * @default {[]}
             */
            _statusList: {
                type: Array,
                default: [],
                required: true,
            },
            /**
             * Identifier for the current status in which the entity is in
             * @type {Number}
             * @default {undefined}
             */
            _currentStatusId: {
                type: Number,
                default: undefined,
                required: true,
            },
            /**
             * The object key for the status identifier
             * @type {String}
             * @default {''}
             */
            _statusKey: {
                type: String,
                default: '',
                required: true,
            },
            /**
             * The object key for the status title
             * @type {String}
             * @default {''}
             */
            _statusTitle: {
                type: String,
                default: '',
                required: true,
            },
            /**
             * Prop to determine if the next bar is in progress (inderteminate)
             * @type {Boolean}
             * @default {false}
             */
            _nextInProgress: {
                type: Boolean,
                default: false,
            },
            /**
             * Status line width
             * @type {Number | String}
             * @default {50}
             */
            _lineWidth: {
                type: Number | String,
                default: 52,
            },
            /**
             * Status line height
             * @type {Number | String}
             * @default {6}
             */
            _lineHeight: {
                type: Number | String,
                default: 5,
            },
            /**
             * Status step icon size
             * @type {Number | String}
             * @default {18}
             */
            _stepIconSize: {
                type: Number | String,
                default: 32,
            },
        },
        data: function () {
            return {
                file: null,
            };
        },
        computed: {
        },
        mounted: function () {
            this.load();
        },
        methods: {
            load: function () {

            },
            iconColor: function (status, isLast) {
                if (this._currentStatusId < status[this._statusKey]) {
                    return "mono-grey-10"
                }
                return "exati-blue-main";
            },
            iconStatus: function (status) {
                if (this._currentStatusId < status[this._statusKey]) {
                    return false
                }
                return true;
            }
        },

    };
</script>
